<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title> จ่ายค่าแรง </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            <v-btn
              class="mx-3"
              fab
              dark
              color="indigo"
              v-on:click="$router.push('/Finance/Wage/History?Type=Wage')"
            >
              <v-icon dark>fa fa-history</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-data-table
          :items-per-page="10"
          :search="search"
          :headers="headers"
          :items="desserts"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="ค้นหา"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mx-2 green" fab dark small @click="Next(item)">
              <v-icon>fa fa-coins</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      desserts: [],
      loading: true,
      search: "",
      headers: [
        {
          text: "ชื่อ",
          align: "left",
          sortable: true,
          value: "UserName",
        },
        {
          text: "เบอร์โทร",
          align: "left",
          sortable: true,
          value: "UserTel",
        },
        {
          text: "ค่าแรง",
          align: "left",
          sortable: true,
          value: "Total",
          width: 80,
        },
        {
          text: "จ่ายแล้ว",
          align: "right",
          sortable: true,
          value: "Paid",
          width: 80,
        },
        {
          text: "คงเหลือ",
          align: "right",
          sortable: true,
          value: "Balance",
          width: 80,
        },
        { width: 80, text: "จ่ายค่าแรง", value: "action", sortable: false },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(null);
    });
  },
  methods: {
    Next(v) {
      this.$router.push("/Finance/Wage/Add?UserId=" + v.UserId);
    },
    async Select(v) {
      this.loading = true;
      const result = await axios.get("/api/v1/select/select/wage?search=" + v);
      if (result.status == 200) {
        this.desserts = result.data;
      }
      this.loading = false;
    },
  },
};
</script>